<template>
  <am-vehicle-not-found v-if="!hasFoundVehicle" :is-ad-mode="isAdMode" />
  <div v-else-if="rawVehicleData !== null" class="am-vdp">
    <div class="am-vdp__inner-wrapper">
      <div class="am-vdp__inner">
        <amui-compact-toolbar
          v-if="appMode === 'widget' && !isAdMode"
          class="am-vdp__compact-toolbar"
        >
          <amui-compact-link
            v-if="favoritesCount"
            :label="$t('vdp.toolbar.favorites')"
            icon="favorite"
            :badge="favoritesCount"
            @click.native="onClickFavorites"
          />
          <amui-compact-link
            :label="$t('vdp.toolbar.search')"
            icon="tune-simple"
            @click.native="onClickSearch"
          />
        </amui-compact-toolbar>
        <div class="am-vdp__scroll-wrapper">
          <div>
            <div class="am-vdp__column-1-header">
              <div class="am-vdp__headline-wrapper">
                <amui-headline
                  :text="vehicleTitle"
                  html-tag="h1"
                  looks-like="h5"
                />
                <button
                  v-if="!isAdMode"
                  :class="{
                    'am-vdp__favorite': true,
                    'am-vdp__favorite--favored': isFavorite
                  }"
                  :aria-label="
                    $t('vdp.favor.' + (isFavorite ? 'remove' : 'add'))
                  "
                  @click="onClickFavor"
                >
                  <amui-icon
                    :name="isFavorite ? 'favorite-filled' : 'favorite'"
                    class="am-icon-size-md"
                  />
                </button>
              </div>
              <div class="am-vdp__highlights">
                <amui-chip
                  v-for="(highlight, i) in vehicleHighlights"
                  :key="highlight + i"
                  :label="highlight.label"
                  :type="highlight.priority"
                ></amui-chip>
                <amui-chip
                  v-if="availability.inWeeks === 0"
                  :key="'availability'"
                  :label="availability.label"
                  :type="'success'"
                ></amui-chip>
                <div
                  v-if="
                    availability.inWeeks !== null && availability.inWeeks > 0
                  "
                  class="am-vdp__availability-calendar"
                >
                  <amui-icon name="calendar-today" />
                  <span>{{ availability.label }}</span>
                </div>
              </div>
            </div>
            <am-vehicle-player
              v-if="rawVehicleData"
              :gallery-items="vehiclePlayerGalleryItems"
              :rotation-closed-items="vehiclePlayerRotationClosedItems"
              :rotation-opened-items="vehiclePlayerRotationOpenedItems"
              :interior-items="vehiclePlayerInteriorItems"
              :navigate-forward-label="$t('vdp.player.navigate.forward')"
              :navigate-backward-label="$t('vdp.player.navigate.backward')"
              :navigate-to-gallery-label="$t('vdp.player.navigate.to.gallery')"
              :navigate-to-rotation-label="
                $t('vdp.player.navigate.to.rotation')
              "
              :navigate-to-interior-label="
                $t('vdp.player.navigate.to.interior')
              "
            ></am-vehicle-player>

            <hr class="am-vdp__separator" />

            <div v-if="usedCarSealSources.length" class="am-vdp__used-car-seal">
              <amui-image
                :sources="usedCarSealSources"
                :alt="$t('vdp.usedCarSeal.imageAlternativeText')"
              />
            </div>

            <div class="am-vdp__column-1-inner">
              <am-highlighted-vehicle-features
                v-if="highlightedFeatures.length"
                :features="highlightedFeatures"
                class="am-vdp__highlighted-features"
              ></am-highlighted-vehicle-features>
            </div>
          </div>
          <div>
            <div class="am-vdp__headline-wrapper">
              <amui-headline
                :text="vehicleTitle"
                html-tag="h1"
                looks-like="h5"
              />
              <button
                v-if="!isAdMode"
                :class="{
                  'am-vdp__favorite': true,
                  'am-vdp__favorite--favored': isFavorite
                }"
                :aria-label="$t('vdp.favor.' + (isFavorite ? 'remove' : 'add'))"
                @click="onClickFavor"
              >
                <amui-icon
                  :name="isFavorite ? 'favorite-filled' : 'favorite'"
                  class="am-icon-size-md"
                />
              </button>
            </div>
            <div class="am-vdp__highlights">
              <amui-chip
                v-for="(highlight, i) in vehicleHighlights"
                :key="highlight + i"
                :label="highlight.label"
                :type="highlight.priority"
              ></amui-chip>
              <amui-chip
                v-if="availability.inWeeks === 0"
                :key="'availability'"
                :label="availability.label"
                :type="'success'"
              ></amui-chip>
              <div
                v-if="availability.inWeeks !== null && availability.inWeeks > 0"
                class="am-vdp__availability-calendar"
              >
                <amui-icon name="calendar-today" />
                <span>{{ availability.label }}</span>
              </div>
            </div>
            <am-highlighted-vehicle-features
              v-if="highlightedFeatures.length"
              :features="highlightedFeatures"
              class="am-vdp__highlighted-features"
            ></am-highlighted-vehicle-features>

            <div v-if="consumptionLegalText" class="am-vdp__consumption-legal">
              <div>
                {{ consumptionLegalText }}<sup>1</sup>
                <button
                  @click="onShowVehicleData"
                  :aria-label="$t('vdp.consumptionInfoCta')"
                >
                  <amui-icon name="info" />
                </button>
              </div>
            </div>

            <div
              v-if="customHighlights.length"
              class="am-vdp__custom-highlights"
            >
              <amui-chip
                v-for="highlight in customHighlights"
                :key="highlight"
                :label="highlight"
                type="secondary"
              ></amui-chip>
            </div>

            <div class="am-vdp__actions">
              <amui-button
                :label="$t('vdp.viewAllFeaturesLabel')"
                type="secondary"
                flat
                icon="list"
                size="sm"
                @click="onShowVehicleData"
              />
              <amui-button
                v-if="vehicleExposeUrl"
                :label="$t('vdp.viewExposeLabel')"
                :href="vehicleExposeUrl"
                target="_blank"
                rel="nofollow noopener"
                type="secondary"
                flat
                icon="print"
                size="sm"
              />
            </div>

            <amui-button-toggle
              v-if="availablePaymentMethods.length > 1"
              :options="availablePaymentMethods"
              v-model="selectedPaymentMethod"
              class="am-vdp__price-methods"
              @change="trackPaymentMethod"
            />
            <div class="am-vdp__price-wrapper">
              <div class="am-vdp__price-display" v-if="selectedPriceData">
                <amui-crossed-out-price
                  v-if="selectedPriceData.crossedOutPrice"
                  :price="
                    $t('price.indications.simple', {
                      price: formatPrice(selectedPriceData.crossedOutPrice)
                    })
                  "
                  :reference-symbol="
                    selectedPriceData.crossedOutPriceReferenceSymbol
                  "
                  class="am-vdp__price-display__label-top"
                />
                <div v-else class="am-vdp__price-display__label-top">
                  {{
                    ['leasing', 'financing'].includes(selectedPriceData.type)
                      ? $t('price.labels.monthly-from')
                      : $t('price.labels.purchase-price')
                  }}
                </div>
                <div class="am-vdp__price-display__price">
                  <amui-headline html-tag="div" looks-like="h4">
                    <span>{{
                      $t('price.indications.simple', {
                        price: formatPrice(selectedPriceData.grossPrice)
                      })
                    }}</span>
                  </amui-headline>
                  <amui-financing-popover
                    v-if="selectedOffer && selectedOffer.type !== 'purchase'"
                    :offer="selectedOffer"
                  />
                </div>
                <div class="am-vdp__price-display__label-bottom">
                  <template
                    v-if="
                      selectedPriceData.vatRate === null &&
                        selectedPriceData.type === 'purchase'
                    "
                  >
                    <span>{{ $t('vat.difference') }}</span>
                  </template>
                  <template v-else-if="selectedPriceData.vatRate !== null">
                    {{
                      $t('price.indications.simple', {
                        price: formatPrice(selectedPriceData.netPrice)
                      })
                    }}
                    <span> {{ $t('price.indications.suffix.net') }}</span>
                  </template>
                </div>
              </div>
            </div>
            <div v-if="usedCarSealSources.length" class="am-vdp__used-car-seal">
              <amui-image
                :sources="usedCarSealSources"
                :alt="$t('vdp.usedCarSeal.imageAlternativeText')"
              />
            </div>

            <div class="am-vdp__price-method-config-options">
              <am-configuration-option
                v-if="hasPriceData"
                :label="$t('vdp.paymentMethods.purchase')"
                :active="selectedPaymentMethod === 'purchase'"
                @click="selectedPaymentMethod = 'purchase'"
              >
                <am-configuration-price-display
                  slot="header"
                  :type="purchasePriceData.type"
                  :gross-price="purchasePriceData.grossPrice"
                  :net-price="purchasePriceData.netPrice"
                  :vat-rate="purchasePriceData.vatRate"
                ></am-configuration-price-display>
                <div
                  v-if="selectedPaymentMethod === 'purchase'"
                  class="am-vdp__price-method-config__cta-wrapper"
                >
                  <amui-button
                    :label="$t('vdp.cta.label')"
                    @click.native="onShowCheckoutProcess"
                  />
                </div>
              </am-configuration-option>
              <am-configuration-option
                v-if="hasLeasingData"
                :label="$t('vdp.paymentMethods.leasing')"
                :link-label="$t('vdp.paymentMethods.adjustRate')"
                :active="selectedPaymentMethod === 'leasing'"
                @click="selectedPaymentMethod = 'leasing'"
              >
                <am-configuration-price-display
                  slot="header"
                  :type="leasingPriceData.type"
                  :gross-price="leasingPriceData.grossPrice"
                  :gross-price-i18n-template="
                    selectedPaymentMethod === 'leasing'
                      ? 'price.indications.monthly.fixed'
                      : 'price.indications.monthly.from'
                  "
                  gross-price-reference-symbol="*"
                  :net-price="leasingPriceData.netPrice"
                  :vat-rate="leasingPriceData.vatRate"
                ></am-configuration-price-display>
                <div
                  v-if="selectedPaymentMethod === 'leasing'"
                  class="am-vdp__price-method-config__cta-wrapper"
                >
                  <amui-button
                    :label="$t('vdp.cta.label')"
                    size="md"
                    viewport="s"
                    @click.native="onShowCheckoutProcess"
                  />
                </div>
                <hr
                  v-if="selectedPaymentMethod !== 'leasing'"
                  class="am-vdp__configuration-option-separator"
                />
                <div
                  class="am-vdp__configuration-option-legal"
                  v-if="selectedPaymentMethod !== 'leasing'"
                >
                  * {{ leasingLegalText }}
                </div>
                <am-offer-configuration-leasing
                  v-if="selectedPaymentMethod === 'leasing'"
                  :is-commercial="settings.isCommercial"
                  :min-rate="settings.financing.minRate"
                  :data="pricing.leasing.normalized"
                  v-model="configurationLeasingModel"
                  @request="onShowCheckoutProcess"
                />
              </am-configuration-option>
              <am-configuration-option
                v-if="hasFinancingData"
                :label="$t('vdp.paymentMethods.financing')"
                :link-label="$t('vdp.paymentMethods.adjustRate')"
                :active="selectedPaymentMethod === 'financing'"
                @click="selectedPaymentMethod = 'financing'"
              >
                <am-configuration-price-display
                  slot="header"
                  :type="financingPriceData.type"
                  :gross-price="financingPriceData.grossPrice"
                  :gross-price-i18n-template="
                    selectedPaymentMethod === 'financing'
                      ? 'price.indications.monthly.fixed'
                      : 'price.indications.monthly.from'
                  "
                  gross-price-reference-symbol="*"
                  :net-price="financingPriceData.netPrice"
                  :vat-rate="financingPriceData.vatRate"
                ></am-configuration-price-display>
                <div
                  v-if="selectedPaymentMethod === 'financing'"
                  class="am-vdp__price-method-config__cta-wrapper"
                >
                  <amui-button
                    :label="$t('vdp.cta.label')"
                    @click.native="onShowCheckoutProcess"
                  />
                </div>
                <hr
                  v-if="selectedPaymentMethod !== 'financing'"
                  class="am-vdp__configuration-option-separator"
                />
                <div
                  class="am-vdp__configuration-option-legal"
                  v-if="selectedPaymentMethod !== 'financing'"
                >
                  * {{ financingLegalText }}
                </div>
                <am-offer-configuration-financing
                  v-if="selectedPaymentMethod === 'financing'"
                  :min-rate="settings.financing.minRate"
                  :data="pricing.financing.normalized"
                  v-model="configurationFinancingModel"
                />
              </am-configuration-option>
            </div>
            <am-dealer-information
              v-if="rawVehicleData"
              :dealer-id="rawVehicleData.mobileSellerId"
              :raw-vehicle-data="rawVehicleData"
              style="margin-top: 96px;"
            />
          </div>
        </div>
        <am-legal-foot-note class="am-vdp__main-legal" />
      </div>
      <am-vehicle-cross-selling
        v-if="rawVehicleData && !isAdMode"
        :referencedRawVehicleData="rawVehicleData"
        :headline="$t('crossSelling.headline')"
        :search-label="$t('crossSelling.searchLabel')"
        :navigate-forward-label="$t('crossSelling.navigate.forward')"
        :navigate-backward-label="$t('crossSelling.navigate.backward')"
        class="am-vdp__cross-selling"
      ></am-vehicle-cross-selling>

      <amui-modal v-model="showVehicleData" @close="onHideVehicleData">
        <am-vehicle-detail-information
          :raw-vehicle-data="rawVehicleData"
          @close="onHideVehicleData"
        ></am-vehicle-detail-information>
      </amui-modal>
      <amui-modal v-model="showCheckoutProcess" @close="onHideCheckoutProcess">
        <am-checkout-process
          :vehicle-object="rawVehicleData"
          :vehicle-title="vehicleTitle"
          :vehicle-price-label="selectedVehiclePriceLabel"
          :sources="vehicleImageSources"
          :fallback-source="vehicleImageFallbackSource"
          :selected-payment-method="selectedPaymentMethod"
          @close="onHideCheckoutProcess"
          @lead="trackGenerateLead"
        />
      </amui-modal>
      <am-vehicle-detail-page-footer
        v-if="vehicleTitle && selectedPriceData"
        :vehicle-title="vehicleTitle"
        :vehicle-price-label="selectedVehiclePriceLabel"
        :sources="vehicleImageSources"
        :fallback-source="vehicleImageFallbackSource"
        :favorites="favoritesCount"
        :is-ad-mode="isAdMode"
        @search="$router.push({ name: 'app' })"
        @favorites="$router.push({ name: 'favorites' })"
        @request="onShowCheckoutProcess"
        class="am-vdp__footer"
        :class="{
          'am-vdp__footer--hidden': !isIntersectingVdp
        }"
      ></am-vehicle-detail-page-footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { AmuiChip } from '@/../ui/components/chip'
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiButton } from '@/../ui/components/button'
import { AmuiButtonToggle } from '@/../ui/components/button-toggle'
import { AmuiFinancingPopover } from '@/../ui/components/financing-popover'
import { AmuiCrossedOutPrice } from '@/../ui/components/crossed-out-price'
import { AmuiImage } from '@/../ui/components/image'
import { AmuiCompactToolbar } from '@/../ui/components/compact-toolbar'
import { AmuiCompactLink } from '@/../ui/components/compact-link'
import { AmuiModal } from '@/../ui/components/modal'

import {
  getTypeLetter as getVehicleTypeLetter,
  getTitle as getVehicleTitle,
  getId as getVehicleId,
  getOfferId as getVehicleOfferId,
  usedCarSealSources as _usedCarSealSources,
  getDeliveryDateInWeeks,
  getPreviewImage as getVehiclePreviewImage
} from '@/../utils/vehicle'
import { resolveSettingByVehicleType } from './../../../../utils/settings'
import {
  getNormalizedPriceData,
  getDiscountInPercent,
  getCrossedOutPrice
} from './../../../../utils/price'
import {
  getNormalizedLeasingData,
  getCheapestLeasingOffer
} from './../../../../utils/leasing'
import {
  getNormalizedFinancingData,
  getCheapestFinancingOffer
} from './../../../../utils/financing'
import vehicleFeatureHelperMixin from '@/app/mixins/vehicle-features-helper.mixin'
import vehicleTrackingMixin from '@/app/mixins/vehicle-tracking.mixin'
import vehicleMixin from '@/app/mixins/vehicle.mixin'
import AmHighlightedVehicleFeatures from './components/highlighted-vehicle-features/highlighted-vehicle-features.vue'
import AmConfigurationOption from './components/configuration-option/configuration-option.vue'
import AmConfigurationPriceDisplay from './components/configuration-price-display/configuration-price-display.vue'
import AmOfferConfigurationFinancing from './components/offer-configuration-financing/offer-configuration-financing.vue'
import AmOfferConfigurationLeasing from './components/offer-configuration-leasing/offer-configuration-leasing.vue'
import AmVehicleDetailInformation from './components/vehicle-detail-information/vehicle-detail-information.vue'
import AmCheckoutProcess from './components/checkout-process/checkout-process.vue'
import AmVehicleDetailPageFooter from './components/vehicle-detail-page-footer/vehicle-detail-page-footer.vue'
import AmLegalFootNote from '@/app/components/legal-foot-note/legal-foot-note'
import AmVehicleNotFound from '@/app/views/vehicle-not-found/vehicle-not-found'

import {
  formatPrice as _formatPrice,
  formatMileage
} from './../../../../utils/formatter'
import structuredClone from '@ungap/structured-clone'
import { crossedOutPriceLegalNotes } from '@/shared/definitions/legal'
import AmVehicleCrossSelling from './components/vehicle-cross-selling/vehicle-cross-selling.vue'
import AmVehiclePlayer from '@/app/components/vehicle-player/vehicle-player.vue'
import vehiclePlayerMixin from './mixins/vehicle-player.mixin'
import seoMixin from './mixins/seo.mixin'
import { getMonth, getYear, parseISO } from 'date-fns'

import { getGTMTracker } from '@/app/tracking/gtm.js'

import AmDealerInformation from '@/app/components/dealer-information/dealer-information.vue'

export default {
  name: 'VehicleDetailPage',

  components: {
    AmuiChip,
    AmuiHeadline,
    AmuiIcon,
    AmuiButton,
    AmuiButtonToggle,
    AmuiModal,
    AmHighlightedVehicleFeatures,
    AmConfigurationOption,
    AmConfigurationPriceDisplay,
    AmOfferConfigurationFinancing,
    AmOfferConfigurationLeasing,
    AmVehicleDetailInformation,
    AmCheckoutProcess,
    AmVehicleDetailPageFooter,
    AmuiCompactLink,
    AmuiCompactToolbar,
    AmuiFinancingPopover,
    AmuiCrossedOutPrice,
    AmuiImage,
    AmVehicleCrossSelling,
    AmVehiclePlayer,
    AmLegalFootNote,
    AmVehicleNotFound,
    AmDealerInformation
  },

  mixins: [
    vehicleFeatureHelperMixin,
    vehiclePlayerMixin,
    vehicleTrackingMixin,
    vehicleMixin,
    seoMixin
  ],

  metaInfo() {
    const meta = []
    const link = []

    if (this.rawVehicleData) {
      if (this.canonicalUrl) {
        link.push({
          rel: 'canonical',
          href: this.canonicalUrl,
          vmid: 'canonical'
        })
      }

      const previewImageSrc = getVehiclePreviewImage(this.rawVehicleData)

      // description
      meta.push({
        name: 'description',
        content: this.seoDescription
      })

      // og
      meta.push({
        property: 'og:site_name',
        content: this.companyName,
        vmid: 'og:site_name'
      })

      meta.push({
        property: 'og:locale',
        content: 'de_DE',
        vmid: 'og:locale'
      })

      meta.push({
        property: 'og:type',
        content: 'website',
        vmid: 'og:type'
      })

      if (this.canonicalUrl) {
        meta.push({
          property: 'og:url',
          content: this.canonicalUrl,
          vmid: 'og:url'
        })
      }

      meta.push({
        property: 'og:title',
        content: this.seoTitle,
        vmid: 'og:title'
      })

      meta.push({
        property: 'og:description',
        content: this.seoDescription,
        vmid: 'og:description'
      })

      if (previewImageSrc) {
        meta.push({
          property: 'og:image',
          content: previewImageSrc.normal,
          vmid: 'og:image'
        })
      }
    }

    return this
      ? {
          title: this.seoTitle,
          titleTemplate: '%s',
          meta,
          link
        }
      : null
  },

  data() {
    return {
      gtm: null,
      selectedPaymentMethod: null,
      defaultPaymentMethod: 'purchase',
      pricing: {
        leasing: {
          normalized: null,
          offer: {
            origin: null,
            modified: null
          }
        },
        financing: {
          normalized: null,
          offer: {
            origin: null,
            modified: null
          }
        },
        price: {
          normalized: null,
          offer: {
            origin: null
          }
        }
      },
      showVehicleData: false,
      showCheckoutProcess: false,
      isIntersectingVdp: false,
      intersectionObserverInstance: null
    }
  },

  computed: {
    ...mapState('core', {
      settings: 'settings',
      appMode: 'appMode'
    }),
    ...mapState('vehicleDetailPage', ['rawVehicleData', 'hasFoundVehicle']),
    ...mapState('favorites', {
      favoritesCount: state => state.vehicles.length
    }),
    ...mapGetters('favorites', ['isFavoriteByVehicleId']),
    ...mapGetters('core', {
      canonicalBaseUrl: 'canonicalBaseUrl',
      companyName: 'companyName',
      _isAdMode: 'isAdMode',
      getDealerByMobileSellerId: 'getDealerByMobileSellerId'
    }),
    ...mapGetters('consent', { consentAllowedServices: 'allowedServices' }),
    rawDealerData() {
      return this.getDealerByMobileSellerId(this.rawVehicleData.mobileSellerId)
    },
    isAdMode() {
      return this._isAdMode || this.$route.query.ad ? true : false
    },
    emissionsCombinedCo2Class() {
      return this.rawVehicleData.emissions?.combined?.co2Class ?? null
    },
    emissionsDischargedCo2Class() {
      return this.rawVehicleData.emissions?.discharged?.co2Class ?? null
    },
    canonicalUrl() {
      const segment = this.$router.resolve(this.$route).resolved.path

      return this.canonicalBaseUrl + segment
    },
    isPluginHybrid() {
      return this.rawVehicleData.hybridPlugin === true
    },
    consumptionLegalText() {
      let text = null

      if (this.rawVehicleData) {
        text = this.getVehicleConsumptionLegalText(this.rawVehicleData)
      }

      return text
    },
    leasingLegalText() {
      const offer = Object.assign(
        {},
        this.pricing.leasing.offer.origin,
        this.pricing.leasing.offer.modified
      )

      return this.$t(
        'vdp.offer.configuration.leasing.shortVersionLegal.template',
        {
          downPayment: this.formatPrice(offer.downPayment, true),
          termOfContract: offer.termOfContract,
          annualMileage: formatMileage(offer.annualMileage)
        }
      )
    },
    financingLegalText() {
      const offer = Object.assign(
        {},
        this.pricing.financing.offer.origin,
        this.pricing.financing.offer.modified
      )

      return this.$t(
        'vdp.offer.configuration.financing.shortVersionLegal.template',
        {
          firstInstallment: this.formatPrice(offer.firstInstallment, true),
          paybackPeriod: offer.paybackPeriod,
          finalInstallment: this.formatPrice(offer.finalInstallment, true)
        }
      )
    },
    vehicleExposeUrl() {
      let exposeUrl = null

      if (this.rawVehicleData) {
        exposeUrl =
          'https://office.carpresenter.de/preisschild_expose.jsp?id=' +
          this.rawVehicleData.mobileAdId
      }

      return exposeUrl
    },
    selectedVehiclePriceLabel() {
      let label = null

      if (this.selectedPriceData !== null) {
        label = this.$t(
          ['leasing', 'financing'].includes(this.selectedPriceData.type)
            ? 'price.indications.monthly.fixed'
            : 'price.indications.simple',
          {
            price: this.formatPrice(this.selectedPriceData.grossPrice)
          }
        )
      }

      return label
    },
    configurationFinancingModel: {
      set(configuration) {
        this.pricing.financing.offer.modified = Object.assign(
          {},
          Object.assign(
            {},
            configuration.rate.origin,
            configuration.rate.modified
          ),
          { vatRate: configuration.vatRate }
        )
      },
      get() {
        let offer = null

        if (this.pricing.financing.offer.modified !== null) {
          let { vatRate, ...rest } = this.pricing.financing.offer.modified // eslint-disable-line no-unused-vars
          offer = rest
        }

        return offer
      }
    },
    configurationLeasingModel: {
      set(configuration) {
        this.pricing.leasing.offer.modified = Object.assign(
          {},
          Object.assign(
            {},
            configuration.rate.origin,
            configuration.rate.modified
          ),
          { vatRate: configuration.vatRate }
        )
      },
      get() {
        let offer = null

        if (this.pricing.leasing.offer.modified !== null) {
          let { vatRate, ...rest } = this.pricing.leasing.offer.modified // eslint-disable-line no-unused-vars
          offer = rest
        }

        return offer
      }
    },
    offerId() {
      let offerId = null

      if (this.rawVehicleData) {
        offerId = getVehicleOfferId(this.rawVehicleData)
      }

      return offerId
    },
    usedCarSealSources() {
      return _usedCarSealSources(this.rawVehicleData)
    },
    isFavorite() {
      let isFavorite = false

      if (this.vehicleId !== null) {
        isFavorite = this.isFavoriteByVehicleId(this.vehicleId)
      }

      return isFavorite
    },
    // details
    vehicleId() {
      let id = null

      if (this.rawVehicleData) {
        id = getVehicleId(this.rawVehicleData)
      }

      return id
    },
    vehicleTitle() {
      let title = null

      if (this.rawVehicleData) {
        title = getVehicleTitle(this.rawVehicleData)
      }

      return title
    },
    vehicleHighlights() {
      let highlights = []

      if (this.rawVehicleData) {
        // usage type
        let usageTypeObject = null

        if (this.rawVehicleData.amUsageType) {
          usageTypeObject = {
            label: this.$t(
              'vehicleClass.' +
                this.rawVehicleData.vehicleClass +
                '.amUsageType' +
                this.rawVehicleData.amUsageType
            ),
            value: this.rawVehicleData.amUsageType
          }
        }

        if (usageTypeObject !== null) {
          highlights.push({
            label: usageTypeObject.label,
            priority: usageTypeObject.value === 'NEW' ? 'primary' : 'secondary'
          })
        }

        if (this.discountInPercent !== null) {
          highlights.push({
            label: '-' + this.discountInPercent + '%',
            priority: 'discount'
          })
        }
      }

      return highlights
    },
    customHighlights() {
      return this.rawVehicleData?.highlights || []
    },
    availability() {
      let availabilityInWeeks = null
      let availability = null

      if (
        this.rawVehicleData?.deliveryDate !== undefined &&
        this.rawVehicleData.amUsageType === 'NEW'
      ) {
        availabilityInWeeks = getDeliveryDateInWeeks(
          this.rawVehicleData.deliveryDate
        )

        if (availabilityInWeeks === 0) {
          availability = this.$t('vdp.deliveryDate.now')
        } else if (availabilityInWeeks <= 12) {
          availability = this.$tc(
            'vdp.deliveryDate.weeks',
            availabilityInWeeks,
            { count: availabilityInWeeks }
          )
        } else {
          const month = getMonth(parseISO(this.rawVehicleData.deliveryDate)) + 1
          const year = getYear(parseISO(this.rawVehicleData.deliveryDate))
          availability = this.$t('vdp.deliveryDate.date', {
            month: this.$t('date.month.short.' + month),
            year
          })
        }
      }

      return {
        inWeeks: availabilityInWeeks,
        label: availability
      }
    },
    selectedOffer() {
      let offer = null

      if (this.selectedPaymentMethod === 'leasing') {
        offer = Object.assign(
          {},
          this.pricing.leasing.offer.origin,
          this.pricing.leasing.offer.modified
        )
      } else if (this.selectedPaymentMethod === 'financing') {
        offer = Object.assign(
          {},
          this.pricing.financing.offer.origin,
          this.pricing.financing.offer.modified
        )
      } else {
        // purchase is default
        offer = this.pricing.price.offer.origin
      }

      return {
        type: this.selectedPaymentMethod,
        data: offer
      }
    },
    selectedPriceData() {
      let priceDisplay = null

      if (this.selectedPaymentMethod === 'leasing') {
        priceDisplay = this.leasingPriceData
      } else if (this.selectedPaymentMethod === 'financing') {
        priceDisplay = this.financingPriceData
      } else {
        // purchase is default
        priceDisplay = this.purchasePriceData
      }

      return priceDisplay
    },
    leasingPriceData() {
      let res = null

      if (this.pricing.leasing.offer.origin !== null) {
        const offer = Object.assign(
          {},
          this.pricing.leasing.offer.origin,
          this.pricing.leasing.offer.modified
        )

        const vatRate = offer.vatRate !== undefined ? offer.vatRate : null

        const netPrice =
          vatRate !== null ? offer.grossRate / (1 + vatRate / 100) : null

        res = {
          type: 'leasing',
          crossedOutPrice: null,
          crossedOutPriceReferenceSymbol: null,
          grossPrice: offer.grossRate,
          netPrice,
          vatRate
        }
      }

      return res
    },
    financingPriceData() {
      let res = null

      if (this.pricing.financing.offer.origin !== null) {
        const offer = Object.assign(
          {},
          this.pricing.financing.offer.origin,
          this.pricing.financing.offer.modified
        )

        const vatRate = offer.vatRate !== undefined ? offer.vatRate : null

        const netPrice =
          vatRate !== null
            ? offer.monthlyInstallment / (1 + vatRate / 100)
            : null

        res = {
          type: 'financing',
          crossedOutPrice: null,
          crossedOutPriceReferenceSymbol: null,
          grossPrice: offer.monthlyInstallment,
          netPrice,
          vatRate
        }
      }

      return res
    },
    purchasePriceData() {
      let res = null

      if (this.pricing.price.offer.origin) {
        const crossedOutPrice = getCrossedOutPrice(
          this.pricing.price.normalized,
          this.vehicleTypeLetter,
          resolveSettingByVehicleType(
            this.settings.priceTrend.maxDaysSinceLastPriceChange,
            this.vehicleTypeLetter
          ),
          resolveSettingByVehicleType(
            this.settings.priceTrend.minPriceDifferenceInPercent,
            this.vehicleTypeLetter
          )
        )

        const crossedOutPriceReferenceSymbol = this.getCrossedOutPriceReferenceSymbol(
          this.vehicleTypeLetter
        )

        res = {
          type: 'purchase',
          crossedOutPrice,
          crossedOutPriceReferenceSymbol,
          grossPrice: this.pricing.price.offer.origin.consumerPriceGross,
          netPrice:
            this.pricing.price.offer.origin.consumerPriceNet !== undefined
              ? this.pricing.price.offer.origin.consumerPriceNet
              : null,
          vatRate:
            this.pricing.price.offer.origin.vatRate !== undefined
              ? this.pricing.price.offer.origin.vatRate
              : null
        }
      }

      return res
    },
    vehicleTypeLetter() {
      let letter = null

      if (this.rawVehicleData) {
        letter = getVehicleTypeLetter(this.rawVehicleData)
      }

      return letter
    },
    discountInPercent() {
      let discountInPercent = null

      if (
        this.vehicleTypeLetter !== null &&
        this.pricing.price.normalized !== null
      ) {
        discountInPercent = getDiscountInPercent(
          this.pricing.price.normalized,
          this.vehicleTypeLetter,
          resolveSettingByVehicleType(
            this.settings.priceTrend.maxDaysSinceLastPriceChange,
            this.vehicleTypeLetter
          ),
          resolveSettingByVehicleType(
            this.settings.priceTrend.minPriceDifferenceInPercent,
            this.vehicleTypeLetter
          )
        )
      }

      return discountInPercent
    },
    availablePaymentMethods() {
      const paymentMethods = []

      if (this.hasPriceData) {
        paymentMethods.push({
          value: 'purchase',
          label: this.$t('vdp.paymentMethods.purchase')
        })
      }

      if (this.hasLeasingData) {
        paymentMethods.push({
          value: 'leasing',
          label: this.$t('vdp.paymentMethods.leasing')
        })
      }

      if (this.hasFinancingData) {
        paymentMethods.push({
          value: 'financing',
          label: this.$t('vdp.paymentMethods.financing')
        })
      }

      return paymentMethods
    },
    hasPriceData() {
      return this.pricing.price.offer.origin !== null
    },
    hasLeasingData() {
      return this.pricing.leasing.offer.origin !== null
    },
    hasFinancingData() {
      return this.pricing.financing.offer.origin !== null
    },
    highlightedFeatures() {
      let features = []

      if (this.rawVehicleData) {
        features = this.getHighlightedFeatures(this.rawVehicleData).concat([
          {
            id: 'offerId',
            label: this.$t('vdp.offerIdentificationLabel', {
              offerId: this.offerId
            }),
            icon: 'label'
          }
        ])
      }

      return features
    },
    vehicleImageSources() {
      let sources = []

      if (this.vehiclePlayerGalleryItems.length) {
        const media = {
          type: this.vehiclePlayerGalleryItems[0].origin,
          normal: this.vehiclePlayerGalleryItems[0].source
        }

        // same sources like for the player as this image will be on same page like the player even it is much smaller
        sources = [
          {
            media: '(min-width: 1281px)',
            srcset: [
              this.getSourceByMediaAndWidth(media, 900),
              this.getSourceByMediaAndWidth(media, 1800) + ' 2x'
            ].join(', ')
          },
          {
            media: '(min-width: 1280)',
            srcset: [
              this.getSourceByMediaAndWidth(media, 1280),
              this.getSourceByMediaAndWidth(media, 2560) + ' 2x'
            ].join(', ')
          },
          {
            media: '(min-width: 900px)',
            srcset: [
              this.getSourceByMediaAndWidth(media, 900),
              this.getSourceByMediaAndWidth(media, 1800) + ' 2x'
            ].join(', ')
          },
          {
            media: '(min-width: 768px)',
            srcset: [
              this.getSourceByMediaAndWidth(media, 768),
              this.getSourceByMediaAndWidth(media, 1536) + ' 2x'
            ].join(', ')
          },
          {
            media: '(min-width: 600px)',
            srcset: [
              this.getSourceByMediaAndWidth(media, 600),
              this.getSourceByMediaAndWidth(media, 1200) + ' 2x'
            ].join(', ')
          },
          {
            media: 'all',
            srcset: [
              this.getSourceByMediaAndWidth(media, 480),
              this.getSourceByMediaAndWidth(media, 960) + ' 2x'
            ].join(', ')
          }
        ]
      }

      return sources
    },
    vehicleImageFallbackSource() {
      return this.vehicleImageSources.length
        ? this.vehicleImageSources[2].srcset
        : null
    }
  },

  watch: {
    '$route.params.id': {
      handler(id) {
        this.fetchRawVehicleData(id)
          .then(res => {
            this.initIntersectionObserver()
            this.trackViewItem(res)
          })
          .catch(() => {})
      }
    },
    rawVehicleData: {
      handler(data) {
        this.setPricing(data)
      },
      immediate: true
    }
  },

  serverPrefetch() {
    return this.fetchRawVehicleData(this.$route.params.id).catch(() => {})
  },

  created() {
    if (['leasing', 'financing'].includes(this.$route.query.pm)) {
      this.selectedPaymentMethod = this.$route.query.pm
    } else {
      this.selectedPaymentMethod = this.defaultPaymentMethod
    }

    this.$watch('selectedPaymentMethod', {
      handler: value => {
        const newValue = value === this.defaultPaymentMethod ? undefined : value
        if (this.$route.query.pm !== newValue) {
          this.$router.replace({
            ...this.$route,
            ...{
              query: {
                ...this.$route.query,
                ...{ pm: newValue }
              }
            }
          })
        }
      },
      immediate: true
    })
  },

  mounted() {
    this.gtm = getGTMTracker()
    if (this.rawVehicleData === null) {
      this.fetchRawVehicleData(this.$route.params.id)
        .then(res => {
          this.initIntersectionObserver()
          this.trackViewItem(res)
        })
        .catch(() => {})
    } else {
      this.initIntersectionObserver()
      this.trackViewItem(this.rawVehicleData)
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (from.params.id !== to.params.id) {
      this.resetVehicleDetailPageState()
    }
    next()
  },

  beforeRouteLeave(to, from, next) {
    this.resetVehicleDetailPageState()
    next()
  },

  // important! we need that because routing guards will not be fired because widget mode use only one route with a virtual routing-resolver component
  beforeDestroy() {
    if (this.appMode === 'widget') {
      this.resetVehicleDetailPageState()
    }

    this.intersectionObserverInstance &&
      this.intersectionObserverInstance.disconnect()
  },

  methods: {
    ...mapActions('vehicleDetailPage', {
      _fetchRawVehicleData: 'fetchRawVehicleData',
      resetVehicleDetailPageState: 'resetState'
    }),
    ...mapActions('favorites', ['toggleFavoriteStateByRawVehicleData']),
    getSourceByMediaAndWidth(media, width) {
      let src = null

      if (media.type === 'cpo') {
        const splittedSegments = media.normal.split('/')
        const filename = splittedSegments.pop()
        src = splittedSegments.join('/') + '/tr:w-' + width + '/' + filename
      } else {
        src = media.normal
      }

      return src
    },
    async fetchRawVehicleData(vehicleId) {
      return this._fetchRawVehicleData(vehicleId)
        .then(res => {
          this.setPricing(res)
          return res
        })
        .catch(() => {
          throw new Error('Vehicle not found')
        })
    },
    initIntersectionObserver() {
      this.intersectionObserverInstance = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            this.isIntersectingVdp = entry.isIntersecting
          })
        },
        { rootMargin: '-100% 0px 0px 0px' }
      )

      this.intersectionObserverInstance.observe(this.$el)
    },
    getCrossedOutPriceReferenceSymbol(vehicleType) {
      const mapping = crossedOutPriceLegalNotes.map(data => {
        return Object.assign({}, data, {
          text: this.$t(data.text)
        })
      })

      const found = mapping.find(d =>
        d.vehicleTypes.includes(vehicleType.toLowerCase())
      )

      return found ? found.referenceNumber : '*'
    },
    formatPrice(value) {
      return _formatPrice(value)
    },
    setPricing(rawVehicleData) {
      const pricing = {
        leasing: {
          normalized: null,
          offer: {
            origin: null,
            modified: null
          }
        },
        financing: {
          normalized: null,
          offer: {
            origin: null,
            modified: null
          }
        },
        price: {
          normalized: null,
          offer: {
            origin: null
          }
        }
      }

      // set normalized data
      pricing.leasing.normalized =
        rawVehicleData?.leasing !== undefined
          ? getNormalizedLeasingData(rawVehicleData.leasing)
          : null

      pricing.financing.normalized =
        rawVehicleData?.financing !== undefined
          ? getNormalizedFinancingData(
              rawVehicleData.financing,
              rawVehicleData.price.vatRate
            )
          : null

      pricing.price.normalized =
        rawVehicleData?.price !== undefined
          ? getNormalizedPriceData(rawVehicleData.price)
          : null

      // set leasing data
      if (pricing.leasing.normalized !== null) {
        const cheapestLeasingOffer = getCheapestLeasingOffer(
          pricing.leasing.normalized.rates,
          this.settings.isCommercial
        )

        if (cheapestLeasingOffer !== null) {
          const { rates, ...rest } = pricing.leasing.normalized // eslint-disable-line no-unused-vars
          pricing.leasing.offer.origin = Object.assign(
            {},
            cheapestLeasingOffer,
            rest
          )
        }
      }

      // set financing data
      if (pricing.financing.normalized !== null) {
        const cheapestFinancingOffer = getCheapestFinancingOffer(
          pricing.financing.normalized.rates,
          this.settings.isCommercial
        )

        if (cheapestFinancingOffer !== null) {
          const { rates, ...rest } = pricing.financing.normalized // eslint-disable-line no-unused-vars
          pricing.financing.offer.origin = Object.assign(
            {},
            cheapestFinancingOffer,
            rest
          )
        }
      }

      // set pricing data
      pricing.price.offer.origin = structuredClone(pricing.price.normalized)

      this.pricing = pricing
    },
    onClickFavor() {
      this.toggleFavoriteStateByRawVehicleData(this.rawVehicleData).then(
        added => {
          if (added) {
            this.trackAddToWishlist(this.rawVehicleData)
          }
        }
      )
    },
    onClickFavorites(ev) {
      ev.preventDefault()
      this.$router.push({ name: 'favorites' })
    },
    onClickSearch(ev) {
      ev.preventDefault()
      this.$router.push({ name: 'app' })
    },
    onShowCheckoutProcess() {
      this.showCheckoutProcess = true
    },
    onHideCheckoutProcess() {
      this.showCheckoutProcess = false
    },
    onClickAutouncle() {
      console.log('Autouncle clicked')
    },
    onShowVehicleData() {
      this.showVehicleData = true
    },
    onHideVehicleData() {
      this.showVehicleData = false
    },
    trackViewItem(item) {
      const payload = {
        ecommerce: {
          items: [this.getVehicleTrackingObject(item)]
        }
      }
      this.gtm.trackEvent({ name: 'view_item', payload })
    },
    trackAddToWishlist(item) {
      const vehicleTrackingObject = this.getVehicleTrackingObject(item)

      const payload = {
        ecommerce: {
          currency: 'EUR',
          value: vehicleTrackingObject.price,
          items: [vehicleTrackingObject]
        },
        component: 'vdp'
      }

      this.gtm.trackEvent({
        name: 'add_to_wishlist',
        payload: payload
      })
    },
    trackGenerateLead(data) {
      let offer = null

      const getTrackingCheckoutType = type => {
        const map = {
          offer: this.$t('tracking.checkout.selection.options.offer'),
          testdrive: this.$t('tracking.checkout.selection.options.testdrive')
        }

        return map[type]
      }

      let payload = {
        value: Math.floor(this.purchasePriceData.grossPrice / 100),
        currency: 'EUR',
        lead_params: {
          payment_type: this.$t(
            'tracking.paymentMethods.' + this.selectedPaymentMethod
          ),
          checkout_type: getTrackingCheckoutType(data.checkoutType)
        }
      }

      if (this.selectedPaymentMethod === 'financing') {
        offer = Object.assign(
          {},
          this.pricing.financing.offer.origin,
          this.pricing.financing.offer.modified
        )
        Object.assign(payload.lead_params, {
          calculator_customer_type: this.$t(
            'vdp.offer.configuration.financing.type.private'
          ),
          calculator_down_payment: Math.floor(offer.firstInstallment / 100),
          calculator_duration: offer.paybackPeriod.toString()
        })
      } else if (this.selectedPaymentMethod === 'leasing') {
        offer = Object.assign(
          {},
          this.pricing.leasing.offer.origin,
          this.pricing.leasing.offer.modified
        )
        Object.assign(payload.lead_params, {
          calculator_customer_type: this.$t(
            'vdp.offer.configuration.leasing.type.' + offer.type.toLowerCase()
          ),
          calculator_mileage_per_year: formatMileage(offer.annualMileage),
          calculator_down_payment: Math.floor(offer.downPayment / 100),
          calculator_duration: offer.termOfContract.toString()
        })
      }

      this.gtm.trackEvent({
        lead_params: null
      })
      this.gtm.trackEvent({
        name: 'generate_lead',
        payload
      })
    },
    trackPaymentMethod() {
      const payload = {
        payment_type: this.$t(
          'tracking.paymentMethods.' + this.selectedPaymentMethod
        )
      }
      this.gtm.trackEvent({ name: 'payment_method_changed', payload })
    }
  }
}
</script>
