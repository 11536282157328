export const technicalPropertyList = [
  'category',
  'doors',
  'seats',
  'gearbox',
  'power',
  'cubicCapacity',
  'wheelFormula',
  'dimension.length',
  'dimension.width',
  'dimension.height',
  'amCurbWeight',
  'amLadenWeight',
  'amPayload',
  'amWheelbase'
]

export default {
  technicalPropertyList
}
