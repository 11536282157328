export const historyPropertyList = [
  'accidentDamaged',
  'damageUnrepaired',
  'numberOfPreviousOwners',
  'taxi',
  'fullServiceHistory',
  'generalInspection'
]

export default {
  historyPropertyList
}
