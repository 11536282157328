<template>
  <div class="am-offer-configuration-leasing">
    <div class="am-offer-configuration-leasing__row">
      <amui-chip
        v-for="option in leasingTypeOptions"
        :key="option.value"
        :label="option.label"
        :selected="option.value === selectedLeasingType"
        :selectable="true"
        @update:selected="onChangeLeasingType(option.value)"
        class="am-offer-configuration-leasing__type"
      />
    </div>

    <template v-if="offersBySelectedLeasingType.length > 1">
      <div
        class="am-offer-configuration-leasing__row am-offer-configuration-leasing__input-items"
      >
        <div class="am-offer-configuration-leasing__input-item">
          <amui-input
            v-model="selectedDownPaymentRawInput"
            :label="$t('vdp.offer.configuration.downPayment.label')"
            @blur="trackCalculatorChanged('calculator_down_payment')"
          />
        </div>
        <div class="am-offer-configuration-leasing__input-item">
          <amui-select
            :options="annualMileageOptions"
            v-model="selectedAnnualMileage"
            :label="$t('vdp.offer.configuration.annualMileage.label')"
            @change="trackCalculatorChanged('calculator_mileage_per_year')"
          />
        </div>
      </div>

      <am-offer-configuration-duration-select
        v-model="selectedDuration"
        :options="termOfContractOptions"
        :label="$t('vdp.offer.configuration.termOfContract.label')"
        class="am-offer-configuration-leasing__duration"
        @change="trackCalculatorChanged('calculator_duration')"
      />

      <am-offer-configuration-rate-table
        v-if="hasVisiblePriceTable"
        class="am-offer-configuration-leasing__row"
        :min-rate="minRate"
        :selected-rate="calculatedRate"
        :down-payment="selectedDownPayment"
        :values="priceTableValues"
        @change="onPriceTableOfferChanged"
        @request="onRequest"
      />
      <amui-button
        v-if="!hasVisiblePriceTable"
        class="am-offer-configuration-leasing__row"
        label="Übersicht aller Raten"
        icon="list"
        flat
        @click="togglePriceTable"
      ></amui-button>
    </template>
    <template v-else>
      <amui-input
        class="am-offer-configuration-leasing__row"
        v-model="selectedDownPaymentRawInput"
        :label="$t('vdp.offer.configuration.downPayment.label')"
        @blur="trackCalculatorChanged('calculator_down_payment')"
      />
      <am-offer-configuration-details
        class="am-offer-configuration-leasing__row"
        :annual-mileage="currentSelectedOffer.annualMileage"
        :term-of-contract="currentSelectedOffer.termOfContract"
      />
    </template>

    <div
      class="am-offer-configuration-leasing__row am-offer-configuration-leasing__small-print"
    >
      * {{ smallPrint }}
    </div>
  </div>
</template>
<script>
import AmOfferConfigurationRateTable from './../offer-configuration-rate-table/offer-configuration-rate-table.vue'
import AmOfferConfigurationDurationSelect from './../offer-configuration-duration-select/offer-configuration-duration-select.vue'
import AmOfferConfigurationDetails from './../offer-configuration-details/offer-configuration-details.vue'
import { unique } from './../../../../../../utils/array'
import { formatPrice, formatMileage } from './../../../../../../utils/formatter'
import { getCheapestLeasingOffer } from './../../../../../../utils/leasing'
import {
  getNewRateByAdjustedDownPayment,
  getMaxDownPayment
} from './../../../../../../utils/down-payment'

import AmuiSelect from '../../../../../../ui/components/select/select.vue'
import AmuiInput from '../../../../../../ui/components/input/input.vue'
import AmuiChip from '../../../../../../ui/components/chip/chip.vue'
import AmuiButton from '../../../../../../ui/components/button/button.vue'

import legalLeasingMixin from './../../mixins/legal-leasing.mixin.js'

import { getGTMTracker } from '@/app/tracking/gtm.js'

export default {
  name: 'AmOfferConfigurationLeasing',

  model: {
    prop: 'offer',
    event: 'change'
  },

  components: {
    AmuiSelect,
    AmuiInput,
    AmuiChip,
    AmuiButton,
    AmOfferConfigurationRateTable,
    AmOfferConfigurationDurationSelect,
    AmOfferConfigurationDetails
  },

  mixins: [legalLeasingMixin],

  data: () => ({
    gtm: null,
    selectedLeasingType: null,
    selectedDuration: null,
    selectedAnnualMileage: null,
    selectedDownPayment: null,
    selectedDownPaymentRawInput: null,
    hasVisiblePriceTable: false
  }),

  props: {
    data: {
      type: Object,
      required: false,
      default: null
    },
    offer: {
      type: Object,
      required: false,
      default: null
    },
    isCommercial: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * the calculated rate must have at least this value.
     * value is expected in cents
     */
    minRate: {
      type: Number,
      required: false,
      default: 1000
    }
  },

  computed: {
    smallPrint() {
      const offer = Object.assign(
        {},
        this.configuredOffer.rate.origin,
        this.configuredOffer.rate.modified
      )

      return this.getLeasingOfferLegalText(
        offer,
        this.data.grossListPrice,
        this.data.comment
      )
    },
    priceTableValues() {
      return this.offersBySelectedLeasingType.map(offer => {
        const rate = this.getRateByOffer(offer)

        return {
          downPayment: offer.downPayment,
          annualMileage: offer.annualMileage,
          duration: offer.termOfContract,
          price: rate
        }
      })
    },
    offersBySelectedLeasingType() {
      return this.data.rates
        .filter(offer => offer.type.toLowerCase() === this.selectedLeasingType)
        .map(offer => {
          if (this.data.vatRate !== undefined) {
            if (this.selectedLeasingType === 'commercial') {
              offer.grossRate = offer.netRate * (1 + this.data.vatRate / 100)
            } else {
              offer.netRate = offer.grossRate / (1 + this.data.vatRate / 100)
            }
          }

          return offer
        })
    },
    leasingTypeOptions() {
      const types = []

      const foundPrivateOffers =
        this.data.rates.find(
          offer => offer.type.toLowerCase() === 'private'
        ) !== undefined

      const foundCommercialOffers =
        this.data.rates.find(
          offer => offer.type.toLowerCase() === 'commercial'
        ) !== undefined

      if (foundPrivateOffers) {
        types.push({
          value: 'private',
          label: this.$t('vdp.offer.configuration.leasing.type.private')
        })
      }

      if (foundCommercialOffers) {
        types.push({
          value: 'commercial',
          label: this.$t('vdp.offer.configuration.leasing.type.commercial')
        })
      }

      return types
    },
    termOfContractOptions() {
      const terms = unique(
        this.offersBySelectedLeasingType.map(offer => offer.termOfContract)
      )

      terms.sort((a, b) => {
        return a - b
      })

      return terms.map(term => ({
        label: term.toString(),
        value: term,
        disabled: !this.isValidOffer(term, this.selectedAnnualMileage)
      }))
    },
    annualMileageOptions() {
      const annualMileages = unique(
        this.offersBySelectedLeasingType.map(offer => offer.annualMileage)
      )

      annualMileages.sort((a, b) => {
        return a - b
      })

      return annualMileages.map(m => {
        return {
          label: this.$t('vdp.offer.configuration.annualMileage.value', {
            value: formatMileage(m)
          }),
          value: m
        }
      })
    },
    currentSelectedOffer() {
      return this.getOffer(this.selectedDuration, this.selectedAnnualMileage)
    },
    calculatedRate() {
      return getNewRateByAdjustedDownPayment(
        this.selectedDownPayment,
        this.currentSelectedOffer.downPayment,
        this.getRateByOffer(this.currentSelectedOffer),
        this.currentSelectedOffer.termOfContract
      )
    },
    calculatedMaxDownPayment() {
      return getMaxDownPayment(
        this.currentSelectedOffer.downPayment,
        this.getRateByOffer(this.currentSelectedOffer),
        this.selectedDuration,
        this.minRate
      )
    },
    isValidCurrentSelectedOffer() {
      return this.isValidOffer(
        this.selectedDuration,
        this.selectedAnnualMileage
      )
    },
    priceSubline() {
      let subline = this.$t('vdp.offer.configuration.vat.difference')

      if (this.data.vatRate !== undefined) {
        subline = this.$t('vdp.offer.configuration.priceLabel.net', {
          value: formatPrice(this.configuredOffer.rate.modified.netRate)
        })
      }

      return subline
    },
    price() {
      return formatPrice(this.calculatedRate)
    },
    configuredOffer() {
      const modified = {
        downPayment: this.selectedDownPayment
      }

      // we need to transform to netRate because calculatedRate is at the moment always a grossRate
      if (this.data.vatRate !== undefined) {
        modified.netRate = this.calculatedRate / (1 + this.data.vatRate / 100)
      }

      modified.grossRate = this.calculatedRate

      return {
        vatRate: this.data.vatRate,
        grossListPrice: this.data.grossListPrice,
        lender: this.data.lender,
        comment: this.data.comment,
        rate: {
          origin: this.currentSelectedOffer,
          modified
        }
      }
    }
  },

  watch: {
    isCommercial(isCommercial) {
      this.setSelectedLeasingType(isCommercial)
    },
    selectedLeasingType(newValue) {
      this.setCheapestOffer()

      if (
        this.$route.query.commercial !== (newValue === 'commercial' ? '1' : '0')
      ) {
        this.$router.replace({
          ...this.$route,
          ...{
            query: {
              ...this.$route.query,
              ...{ commercial: newValue === 'commercial' ? 1 : undefined }
            }
          }
        })
      }
    },
    selectedDuration(value) {
      if (this.isValidCurrentSelectedOffer === false) {
        this.setCheapestOffer('termOfContract', value)
      }
    },
    selectedAnnualMileage(value) {
      if (this.isValidCurrentSelectedOffer === false) {
        this.setCheapestOffer('annualMileage', value)
      }
    },
    selectedDownPaymentRawInput(value) {
      // the selectedDownPaymentRawInput is necessary to keep reactivity for the input field
      let input = value.replace(/[^0-9]/g, '')

      if (input.length > 0) {
        input = parseInt(input) * 100 // to cents

        if (input < 0) {
          input = 0
        } else if (input > this.calculatedMaxDownPayment) {
          input = this.calculatedMaxDownPayment
        }
      }

      this.selectedDownPayment = input

      this.$nextTick(() => {
        this.selectedDownPaymentRawInput = formatPrice(input)
      })
    },
    selectedDownPayment(value) {
      this.selectedDownPaymentRawInput = formatPrice(value).toString()
    }
  },

  created() {
    this.setSelectedLeasingType(
      this.isCommercial || this.$route.query.commercial === '1'
    )

    if (this.offer !== null) {
      this.setOffer(this.offer)
    } else {
      this.setCheapestOffer()
    }

    this.$watch('configuredOffer', offer => {
      this.$emit('change', offer)
    })
  },

  mounted() {
    this.gtm = getGTMTracker()
  },

  methods: {
    getOffer(duration, annualMileage) {
      return this.offersBySelectedLeasingType.find(offer => {
        return (
          offer.termOfContract === duration &&
          offer.annualMileage === annualMileage
        )
      })
    },
    isValidOffer(duration, annualMileage) {
      return (
        this.getOffer(duration, annualMileage) !== undefined &&
        this.calculatedRate > 0
      )
    },
    // returns the rate (price) of the offer in gross (net is change request for future)
    getRateByOffer(offer) {
      return offer.grossRate
    },
    onPriceTableOfferChanged(offer) {
      this.selectedDuration = offer.duration
      this.selectedAnnualMileage = offer.annualMileage
      this.trackCalculatorChanged('rate')
    },
    onRequest() {
      // the payload might be not needed for vehicle market, but for any other integrations of the price calculator
      this.$emit('request')
    },
    setCheapestOffer(filterKey, filterValue) {
      let offers = [...this.offersBySelectedLeasingType]

      if (filterKey !== undefined && filterValue !== undefined) {
        offers = this.offersBySelectedLeasingType.filter(
          offer => offer[filterKey] === filterValue
        )
      }

      const offer = getCheapestLeasingOffer(
        offers,
        this.selectedLeasingType === 'commercial'
      )

      this.setOffer(offer)
    },
    setSelectedLeasingType(isCommercial) {
      const leasingType = isCommercial ? 'commercial' : 'private'

      if (
        this.leasingTypeOptions.find(o => o.value === leasingType) !== undefined
      ) {
        this.selectedLeasingType = leasingType
      }
    },
    togglePriceTable() {
      this.hasVisiblePriceTable = !this.hasVisiblePriceTable
    },
    onChangeLeasingType(value) {
      if (value !== this.selectedLeasingType) {
        this.trackCalculatorChanged('calculator_customer_type')
        this.selectedLeasingType = value
      }
    },
    setOffer(offer) {
      this.selectedDownPayment = offer.downPayment
      this.selectedDuration = offer.termOfContract
      this.selectedAnnualMileage = offer.annualMileage
    },
    trackCalculatorChanged(field) {
      const type = this.$t('tracking.paymentMethods.leasing')

      const customerType = this.leasingTypeOptions.find(
        o => o.value === this.selectedLeasingType
      ).label

      const downPayment = this.selectedDownPayment / 100

      const annualMileage = formatMileage(this.selectedAnnualMileage)

      const duration = this.selectedDuration.toString()

      let payload = {
        calculator_type: type,
        calculator_customer_type: customerType,
        currency: 'EUR',
        value: Math.floor(this.calculatedRate / 100)
      }

      if (field === 'calculator_down_payment') {
        payload[field] = downPayment
      } else if (field === 'calculator_customer_type') {
        Object.assign(payload, {
          calculator_mileage_per_year: annualMileage,
          calculator_duration: duration,
          calculator_down_payment: downPayment
        })
      } else if (field === 'calculator_mileage_per_year') {
        payload[field] = annualMileage
      } else if (field === 'calculator_duration') {
        payload[field] = duration
      } else if (field === 'rate') {
        Object.assign(payload, {
          calculator_mileage_per_year: annualMileage,
          calculator_duration: duration
        })
      }

      this.gtm.trackEvent({
        name: 'calculator_changed',
        payload
      })
    }
  }
}
</script>
