<template>
  <am-vehicle-cross-selling-slider
    v-if="vehicles.length"
    :headline="headline"
    :vehicles="vehicles"
    :search-label="searchLabel"
    :navigate-forward-label="navigateForwardLabel"
    :navigate-backward-label="navigateBackwardLabel"
    :search-href="searchHref"
    @view="onViewVehicle"
    @favor="onFavoriteChange"
    @search="onSearch"
    class="am-vehicle-scross-selling"
  ></am-vehicle-cross-selling-slider>
</template>

<script>
import { getPricing, getId as getVehicleId } from '@/app/../../utils/vehicle'
import vehiclePriceHelperMixin from '@/app/mixins/vehicle-price-helper.mixin'
import vehicleFeatureHelperMixin from '@/app/mixins/vehicle-features-helper.mixin'
import vehicleCardHelperMixin from '@/app/mixins/vehicle-card-helper.mixin'
import { mapActions, mapState } from 'vuex'
import { getUrlQueryByLocale } from '@/app/mixins/search-params-mapping.mixin'
import AmVehicleCrossSellingSlider from '@/app/components/vehicle-cross-selling-slider/vehicle-cross-selling-slider.vue'
import vehicleTrackingMixin from '@/app/mixins/vehicle-tracking.mixin'

import { getGTMTracker } from '@/app/tracking/gtm.js'

export default {
  name: 'AmVehicleCrossSelling',

  components: {
    AmVehicleCrossSellingSlider
  },

  mixins: [
    vehiclePriceHelperMixin,
    vehicleFeatureHelperMixin,
    vehicleCardHelperMixin,
    vehicleTrackingMixin
  ],

  props: {
    referencedRawVehicleData: {
      type: Object,
      required: true
    },
    headline: {
      type: String,
      required: true
    },
    searchLabel: {
      type: String,
      required: true
    },
    navigateForwardLabel: {
      type: String,
      required: true
    },
    navigateBackwardLabel: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      gtm: null
    }
  },

  computed: {
    ...mapState('vehicleDetailPage', ['rawCrossSellingVehicleData']),

    vehicles() {
      let vehicles = []

      if (Array.isArray(this.rawCrossSellingVehicleData)) {
        vehicles = this.rawCrossSellingVehicleData.map(rawVehicle => {
          const vehicleData = this.getMappedVehicleCardData(rawVehicle)

          let sources = []
          let fallbackSource = null

          if (vehicleData.media.length > 0) {
            const media = vehicleData.media[0]

            sources = [
              {
                media: 'all',
                srcset: [
                  this.getSourceByMediaAndWidth(media, 480),
                  this.getSourceByMediaAndWidth(media, 960) + ' 2x'
                ].join(', ')
              }
            ]

            fallbackSource = sources[0].srcset
          }

          return Object.assign({}, vehicleData, {
            sources,
            fallbackSource: fallbackSource
          })
        })
      }

      return vehicles
    },

    searchHref() {
      const query = getUrlQueryByLocale(this.getSimilarVehicleParams())
      return this.$router.resolve({
        name: 'app',
        query: Object.assign({}, query, {
          sort: 'desc'
        })
      }).href
    }
  },

  watch: {
    referencedRawVehicleData() {
      this.resetRawCrossSellingVehicleData().then(() =>
        this.fetchRawCrossSellingVehicleData()
      )
    }
  },

  serverPrefetch() {
    return this.fetchRawCrossSellingVehicleData()
  },

  mounted() {
    this.gtm = getGTMTracker()

    if (!this.rawCrossSellingVehicleData) {
      this.fetchRawCrossSellingVehicleData()
    }
  },

  methods: {
    ...mapActions('favorites', ['toggleFavoriteStateByRawVehicleData']),
    ...mapActions('vehicleDetailPage', {
      _fetchRawCrossSellingVehicleData: 'fetchRawCrossSellingVehicleData',
      resetRawCrossSellingVehicleData: 'resetRawCrossSellingVehicleData'
    }),
    getSourceByMediaAndWidth(media, width) {
      let src = null

      if (media.type === 'cpo') {
        const splittedSegments = media.normal.split('/')
        const filename = splittedSegments.pop()
        src = splittedSegments.join('/') + '/tr:w-' + width + '/' + filename
      } else {
        src = media.normal
      }

      return src
    },
    async fetchRawCrossSellingVehicleData() {
      return this._fetchRawCrossSellingVehicleData({
        params: this.getSimilarVehicleParams(),
        options: {
          limit: 10,
          sort: '-price.amConsumerPriceGrossCent mobileAdId'
        }
      }).catch(err => {
        // TODD: error handling
        console.log('error handling missing', err)
      })
    },
    onSearch() {
      // TODO: issue with scroll top when changing routes
      window.location = this.searchHref
    },
    onViewVehicle(vehicleId) {
      this.trackSelectItem(
        this.rawCrossSellingVehicleData.find(
          raw => vehicleId === getVehicleId(raw)
        )
      )

      this.$router.push({
        name: 'detail',
        params: {
          id: vehicleId
        }
      })
    },
    onFavoriteChange(vehicleId) {
      if (Array.isArray(this.rawCrossSellingVehicleData)) {
        const foundRawVehicleData = this.rawCrossSellingVehicleData.find(
          rawVehicleData => {
            return getVehicleId(rawVehicleData) === vehicleId
          }
        )

        if (foundRawVehicleData !== undefined) {
          this.toggleFavoriteStateByRawVehicleData(foundRawVehicleData).then(
            added => {
              if (added) {
                this.trackAddToWishlist(foundRawVehicleData)
              }
            }
          )
        }
      }
    },
    getSimilarVehicleParams() {
      const pricing = getPricing(this.referencedRawVehicleData)
      const priceDeviationInPercent = 5

      return {
        category: this.referencedRawVehicleData.category,
        flags: [],
        vehicleClass: this.referencedRawVehicleData.vehicleClass,
        consumerPriceGrossRange: {
          min: Math.round(
            (pricing.offer.data.consumerPriceGross *
              ((100 - priceDeviationInPercent) / 100)) /
              100
          ),
          max: Math.round(
            (pricing.offer.data.consumerPriceGross *
              ((100 + priceDeviationInPercent) / 100)) /
              100
          )
        }
      }
    },

    trackSelectItem(item) {
      const payload = {
        ecommerce: {
          items: [this.getVehicleTrackingObject(item)]
        }
      }
      this.gtm.trackEvent({
        name: 'select_item',
        payload
      })
    },

    trackAddToWishlist(item) {
      const vehicleTrackingObject = this.getVehicleTrackingObject(item)

      const payload = {
        ecommerce: {
          currency: 'EUR',
          value: vehicleTrackingObject.price,
          items: [vehicleTrackingObject]
        },
        component: 'vdp-cross-selling'
      }

      this.gtm.trackEvent({
        name: 'add_to_wishlist',
        payload
      })
    }
  }
}
</script>
