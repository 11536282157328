export const categorizedFeaturesPropertyList = {
  exteriorTrim: [
    'nightVisionAssist',
    'panoramicGlassRoof',
    'roofRails',
    'sunroof',
    'windshield'
  ],
  entertainment: [
    'cdPlayer',
    'integratedMusicStreaming',
    'onBoardComputer',
    'radio',
    'soundSystem',
    'touchscreen',
    'tv'
  ],
  safety: [
    'automaticRainSensor',
    'bendingLightsType',
    'blindSpotMonitor',
    'daytimeRunningLamps',
    'emergencyCallSystem',
    'fatigueWarningSystem',
    'frontFogLights',
    'glareFreeHighBeam',
    'handsFreePhoneSystem',
    'headlightType',
    'headlightWasherSystem',
    'headUpDisplay',
    'highBeamAssist',
    'immobilizer',
    'isofix',
    'lightSensor',
    'passengerSeatIsofixPoint',
    'tirePressureMonitoring',
    'tractionControlSystem',
    'voiceControl',
    'winterPackage',
    'winterTires',
    'abs',
    'esp',
    'rollOverBar'
  ],
  connectivity: [
    'androidAuto',
    'bluetooth',
    'carplay',
    'navigationSystem',
    'usb',
    'wifiHotspot',
    'wirelessCharging'
  ],
  comfort: [
    'alarmSystem',
    'ambientLighting',
    'armRest',
    'auxiliaryHeating',
    'cargoBarrier',
    'centralLocking',
    'dimmingInteriorMirror',
    'disabledAccessible',
    'electricExteriorMirrors',
    'electricTailgate',
    'electricWindows',
    'foldFlatPassengerSeat',
    'heatedSteeringWheel',
    'heatedWindshield',
    'keylessEntry',
    'leatherSteeringWheel',
    'multifunctionalWheel',
    'paddleShifters',
    'powerAssistedSteering',
    'box',
    'electricStarter',
    'heatedSteeringWheel',
    'kickstarter'
  ],
  driverAssistance: [
    'collisionAvoidance',
    'distanceWarningSystem',
    'laneDepartureWarning',
    'speedLimiter',
    'trafficSignRecognition',
    'parkingAssistants'
  ],
  drivetrain: [
    'fourWheelDrive',
    'hillStartAssist',
    'startStopSystem',
    'catalyticConverter'
  ],
  suspension: ['airSuspension', 'performanceHandlingSystem', 'sportPackage'],
  climateControl: [
    {
      key: 'climatisation',
      value: 'MANUAL_CLIMATISATION'
    },
    {
      key: 'climatisation',
      value: 'AUTOMATIC_CLIMATISATION'
    },
    {
      key: 'climatisation',
      value: 'AUTOMATIC_CLIMATISATION_2_ZONES'
    },
    {
      key: 'climatisation',
      value: 'AUTOMATIC_CLIMATISATION_3_ZONES'
    },
    {
      key: 'climatisation',
      value: 'AUTOMATIC_CLIMATISATION_4_ZONES'
    }
  ],
  interiorTrim: ['digitalCockpit', 'skiBag', 'smokersPackage'],
  seats: [
    'electricAdjustableSeats',
    'electricBackseatAdjustment',
    'electricHeatedRearSeats',
    'electricHeatedSeats',
    'lumbarSupport',
    'massageSeats',
    'sportSeats',
    'ventilatedSeats'
  ],
  wheels: [
    'alloyWheels',
    'allSeasonTires',
    'breakdownService',
    'steelWheels',
    'summerTires'
  ]
}

export default {
  categorizedFeaturesPropertyList
}
